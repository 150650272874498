import React from 'react'
import { Link } from 'react-router-dom'

function WhyUs() {
    return (
        <>
            <section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                <div className="text-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 v-center">
                                <div className="bread-inner">
                                    <div className="bread-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><a href="#">Why Us</a></li>
                                        </ul>
                                    </div>
                                    <div className="bread-title">
                                        <h2>Why Us</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="about-sec bg-gradient5 pad-tb">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-10">
                            <div class="common-heading">

                                <h1 class="mb30">Why Choose <span
                                    class="text-radius text-light text-animation bg-b">N D Techland</span></h1>
                                <p>We make experience that At N D Techland Pvt. Ltd., we understand that choosing the right partner for your IT needs is crucial for the success and growth of your business.
                                </p>
                                <p>Here are several compelling reasons why partnering with us sets you on the path to digital excellence.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="why-choos-lg pad-tb">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common-heading text-l">

                                <h2 class="mb20">Why N D Techland Ranked Top Among The Leading Web & App Development Companies</h2>
                                <p>Following are some reason of our successful journey of being on top of the leading web and app development companies.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="itm-media-object mt40 tilt-3d">
                                <div class="media">
                                    <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img
                                        src="images/icons/computers.svg" alt="icon" class="layer" /></div>
                                    <div class="media-body">
                                        <h4>Expertise Across Diverse Domains</h4>
                                        <p style={{ textAlign: 'justify' }}>With a team of seasoned professionals who are experts in their respective fields, we bring a wealth of experience and knowledge to the table. Whether it's developing intricate web applications, crafting intuitive mobile solutions, executing targeted digital marketing campaigns, or fortifying your IT infrastructure against evolving cyber threats, our expertise spans across diverse domains, ensuring that we can cater to all your IT requirements under one roof.</p>
                                    </div>
                                </div>
                                <div class="media mt40">
                                    <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img
                                        src="images/icons/worker.svg" alt="icon" class="layer" /></div>
                                    <div class="media-body">
                                        <h4>Tailored Solutions for Your Business</h4>
                                        <p style={{ textAlign: 'justify' }}>We understand that every business is unique, with its own set of challenges, objectives, and aspirations. That's why we take a personalized approach to every project we undertake. By collaborating closely with our clients, we gain valuable insights into their specific needs and requirements, allowing us to tailor solutions that are aligned with their business goals and objectives. Our focus is always on delivering solutions that not only meet but exceed our clients' expectations.</p>
                                    </div>
                                </div>
                                <div class="media mt40">
                                    <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"> <img
                                        src="images/icons/deal.svg" alt="icon" class="layer" /></div>
                                    <div class="media-body">
                                        <h4>Innovation at the Forefront</h4>
                                        <p style={{ textAlign: 'justify' }}>Innovation is at the heart of everything we do at N D Techland. We are constantly pushing the boundaries of technology, exploring new ideas, and adopting emerging trends to stay ahead of the curve. Whether it's leveraging the latest frameworks and tools for application development, harnessing the power of data analytics for digital marketing, or implementing cutting-edge security protocols to protect your digital assets, we are committed to driving innovation that delivers tangible results for our clients.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="itm-media-object mt40 tilt-3d">
                                <div class="media">
                                    <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img
                                        src="images/icons/computers.svg" alt="icon" class="layer" /></div>
                                    <div class="media-body">
                                        <h4> Commitment to Quality and Excellence</h4>
                                        <p style={{ textAlign: 'justify' }}> Quality and excellence are non-negotiable principles that underpin everything we do. From the initial concept and design phase to the final delivery and beyond, we adhere to the highest standards of quality and craftsmanship in every aspect of our work. Our rigorous testing processes ensure that our solutions are robust, reliable, and scalable, enabling our clients to achieve their business objectives with confidence and peace of mind.
                                        </p>
                                    </div>
                                </div>
                                <div class="media mt40">
                                    <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"><img
                                        src="images/icons/worker.svg" alt="icon" class="layer" /></div>
                                    <div class="media-body">
                                        <h4>Transparent Communication and Collaboration</h4>
                                        <p style={{ textAlign: 'justify' }}> Effective communication and collaboration are the cornerstones of successful partnerships. At N D Techland, we believe in fostering open, transparent, and collaborative relationships with our clients. We keep you informed and involved at every stage of the project, soliciting your feedback and insights to ensure that our solutions are precisely tailored to your needs. Our dedicated team of professionals is always on hand to address any questions, concerns, or challenges that may arise, providing you with the support and guidance you need to succeed in the digital landscape.</p>
                                    </div>
                                </div>
                                <div class="media mt40">
                                    <div class="img-ab- base" data-tilt data-tilt-max="20" data-tilt-speed="1000"> <img
                                        src="images/icons/deal.svg" alt="icon" class="layer" /></div>
                                    <div class="media-body">
                                        <h4>Customer-Centric Approach</h4>
                                        <p style={{ textAlign: 'justify' }}>At the heart of our business philosophy is a relentless focus on customer satisfaction. We measure our success not by the projects we complete but by the relationships we build and the value we deliver to our clients. Your success is our success, and we go above and beyond to ensure that every interaction you have with us is positive, productive, and memorable.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default WhyUs