import React from 'react'
import  security  from "../Image/security.png"

function Itsecurity() {
  return (
    <div>
    <section >
      <img class="d-block w-100" src={security} alt="img" />
    </section>

  </div>
  )
}

export default Itsecurity