import React from 'react'

const Faq = () => {
    return (
        <div>


            <section class="faq-section section-big-py-space b-g-light">
                <div class="container" style={{ marginTop: "8rem" }}>
                    <div class="row">
                        <div class="col-12">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingone">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseone"  >
                                            What services does N D Techland Private Limited provide?

                                        </button>
                                    </h2>
                                    <div id="collapseone" class="accordion-collapse collapse show" aria-labelledby="headingone" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We offer a range of services including software development, web and mobile app development, website designing, and internet marketing solutions. We cater to businesses of all sizes, providing custom solutions to meet your unique needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingtwo">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwo"  >
                                            How can I request a proposal for my project?
                                        </button>
                                    </h2>
                                    <div id="collapsetwo" class="accordion-collapse collapse " aria-labelledby="headingtwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>You can request a proposal by contacting us via email at info@ndtechland.com or by calling our customer support team at 0120 4207742. We will discuss your project requirements and provide a detailed proposal based on your needs.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingthree">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree"  >
                                            What is the payment structure for projects?

                                        </button>
                                    </h2>
                                    <div id="collapsethree" class="accordion-collapse collapse " aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Our payment structure typically includes a percentage of the total project cost due at different milestones. These milestones are outlined in the project proposal and contract. We require an initial deposit before starting work on the project.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingfour">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour"  >
                                            Can I cancel my project after it has been started?

                                        </button>
                                    </h2>
                                    <div id="collapsefour" class="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, you can cancel your project under certain conditions: <br />
                                                If the project has started but is not completed within 15 days of accepting the proposal, 50% of the total project cost is due upon cancellation.
                                                If the project is accepted but has not yet started, 25% of the total project cost is due upon cancellation. Please refer to our Refund Policy for more details.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingfive">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive"  >
                                            How long does it take to complete a project?

                                        </button>
                                    </h2>
                                    <div id="collapsefive" class="accordion-collapse collapse " aria-labelledby="headingfive" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>The timeline for project completion depends on the complexity and scope of the project. During the proposal stage, we will provide an estimated timeline based on your specific requirements. We strive to meet all deadlines, and we keep you informed throughout the development process.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" >
                                            What happens after my project is completed?

                                        </button>
                                    </h2>
                                    <div id="collapsesix" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Once your project is completed and delivered, we offer post-launch support and maintenance services as needed. These services can include updates, bug fixes, and ongoing optimization to ensure your project continues to perform optimally.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseseven" >
                                            Do you offer ongoing maintenance and support?


                                        </button>
                                    </h2>
                                    <div id="collapseseven" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, we offer ongoing maintenance and support packages for websites, apps, and software projects. Our support plans are flexible and can be customized based on your specific needs. You can contact us to discuss the options available.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeight" >
                                            What if I need changes after the project is delivered?
                                        </button>
                                    </h2>
                                    <div id="collapseeight" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We understand that changes may be necessary even after the project is delivered. We offer additional services for post-launch modifications, updates, or enhancements. Depending on the nature of the changes, we will provide a separate quote or include them in a support agreement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsenine" >
                                            Do you offer SEO and digital marketing services?

                                        </button>
                                    </h2>
                                    <div id="collapsenine" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Yes, we provide comprehensive digital marketing services, including search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, and content marketing. Our internet marketing solutions are designed to help you increase your online visibility and grow your business.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseten" >
                                            How do you ensure the security of my data?
                                        </button>
                                    </h2>
                                    <div id="collapseten" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>We take data security very seriously. We implement robust security measures, including encryption, secure hosting, and regular security audits, to protect your data. Our development practices adhere to industry best standards for cybersecurity.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseeleven" >
                                        What are your working hours?
                                        </button>
                                    </h2>
                                    <div id="collapseeleven" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>Our working hours are Monday through Friday, 9:00 AM to 6:00 PM IST. However, we understand that some projects may require support outside of regular hours, and we can accommodate specific needs as discussed during project planning.


                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingsix">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapsetwlve" >
                                        How do I contact customer support?

                                        </button>
                                    </h2>
                                    <div id="collapsetwlve" class="accordion-collapse collapse " aria-labelledby="headingsix" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <p>You can reach our customer support team by emailing info@ndtechland.com or calling 0120 4207742. We are here to assist you with any questions or concerns you may have regarding your project or our services.



                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />

        </div>
    )
}

export default Faq
