import React from 'react'
import { Link } from 'react-router-dom'
const Refundpolicy = () => {
    return (
        <div>

            <section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                <div className="text-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 v-center">
                                <div className="bread-inner">
                                    <div className="bread-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><a href="#">Footer</a></li>
                                        </ul>
                                    </div>
                                    <div className="bread-title">
                                        <h2>Refund Policy</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-agency pad-tb block-1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="common-heading text-l ">
                                <span>Refund Policy</span>
                                <h3>No Refund After Project Completion</h3>
                                <p style={{ textAlign: "justify" }}> Once the development of a project has been completed and delivered to the customer, no refund will be applicable. Completion is defined as the final delivery of the agreed-upon services or products, including any milestones that have been outlined in the project agreement.
                                </p>
                            </div>

                            <h3>Project Cancellation After Work Has Started</h3>

                            <ul>
                                <li>⦁ If a project has commenced and work has been in progress for up to 15 days following the acceptance of the proposal, but the project has not yet been completed, the customer may cancel the project.
                                </li> <br />
                                <li>⦁ In such a case, 50% of the total project cost will be due and payable by the customer. This amount covers the work, resources, and efforts invested by N D Techland Private Limited up to the date of cancellation</li><br />


                            </ul>
                            <br />
                            <h3>Project Cancellation Before Work Has Started</h3>

                            <ul>
                                <li>⦁ If the project has been accepted by the customer and all agreements have been signed by both parties, but the development work has not yet started, the customer may cancel the project
                                </li> <br />
                                <li>⦁ In this scenario, the customer will be required to pay 25% of the total project cost. This fee covers administrative efforts, resources allocated, and opportunity costs.
                                </li><br />


                            </ul>
                            <br />
                            <h3>Conditions of Refund Eligibility</h3>

                            <ul>
                                <li>⦁ Refund requests must be submitted in writing via email to info@ndtechland.com or by contacting our customer support team at 0120 4207742.

                                </li> <br />
                                <li>⦁ Refunds will be processed according to the specific terms mentioned above. Refund eligibility is contingent on the status of the project and the point at which cancellation is requested
                                </li><br />


                            </ul>
                            <br />
                            <h3>Exceptions</h3>

                            <p>This refund policy does not apply to:</p>

                            <ul>
                                <li>⦁ Projects where additional services have been rendered beyond the scope of the original agreement, such as post-launch support or maintenance services.

                                </li> <br />
                                <li>⦁ Any third-party costs that have been incurred on behalf of the customer as part of the project (e.g., software licenses, domain registration, hosting fees, etc.).

                                </li><br />


                            </ul>
                            <br />
                            <br />
                            <h3>Governing Law</h3>

                            <p>This Refund Policy shall be governed by and construed in accordance with the laws of India. Any disputes arising under or in connection with this policy shall be subject to the exclusive jurisdiction of the courts located in Noida, India</p>

                            <br />

                            <h3>Contact Us
                            </h3>

                            <ul>
                                <li>If you have any questions or concerns about this Privacy Policy, please contact us at:

                                </li> <br />
                                <li>N D Techland Private Limited

                                </li><br />
                                <li>C-53, Sector 2, Noida-201301
                                </li><br />
                                <li>Email: info@ndtechland.com
                                </li><br />

                                <p>Customer Support: 0120 4207742</p>


                            </ul>



                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Refundpolicy
