import React from 'react'
import { Link } from 'react-router-dom'
const Privacypolicy = () => {
    return (
        <div>

            <section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                <div className="text-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 v-center">
                                <div className="bread-inner">
                                    <div className="bread-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><a href="#">Footer</a></li>
                                        </ul>
                                    </div>
                                    <div className="bread-title">
                                        <h2>Privacy Policy</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-agency pad-tb block-1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="common-heading text-l ">
                                <span>Privacy Policy</span>
                                <h3>Introduction</h3>
                                <p style={{ textAlign: "justify" }}> At N D Techland Private Limited ("Company"), we value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you engage with our software, web apps, mobile apps, website designing services, and internet marketing solutions (collectively, the "Services").

                                    <br />
                                    <br />
                                    By using our Services, you consent to the data practices described in this policy.</p>
                            </div>

                            <h3>Information We Collect</h3>
                            <p>We may collect and process the following types of information:</p>

                            <ul>
                                <li>⦁ <b>Personal Information:</b> Name, email address, phone number, and other contact details.</li> <br />
                                <li>⦁ <b>Usage Data:</b> Details about how you interact with our Services, such as IP address, browser type, device information, and pages visited.</li><br />
                                <li>⦁ <b>Transaction Information:</b> Payment details, billing information, and transaction history.</li><br />
                                <li>⦁ <b>Cookies and Tracking Technologies: </b> We may use cookies and similar tracking technologies to enhance your experience on our platform.</li>

                            </ul>
                            <br />

                            <h3>How We Use Your Information</h3>

                            <p>We use your information for various purposes, including but not limited to:</p>

                            <ul>
                                <li>⦁ Providing and improving our Services.</li> <br />
                                <li>⦁ Processing transactions and sending related information, such as purchase confirmations and invoices.
                                </li><br />
                                <li>⦁ Responding to your inquiries, providing customer support, and sending administrative information.
                                </li><br />
                                <li>⦁ Personalizing your experience with our Services, including showing relevant advertisements and content.
                                </li><br />
                                <li>⦁ Conducting analytics and research to improve our platform and services
                                </li>

                            </ul>

                            <br />

                            <h3>Disclosure of Your Information</h3>

                            <p>We may share your personal data with:</p>
                            <br />
                            <ul>
                                <li>⦁ <b>Service Providers:</b> Third-party vendors who assist us in providing our Services, such as payment processors, hosting providers, and marketing partners.
                                </li> <br />
                                <li>⦁ <b>Legal Compliance: </b>If required by law, to comply with legal obligations, or in response to a valid legal request, such as a subpoena or court order.
                                </li><br />
                                <li>⦁ <b>Business Transfers: </b>In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred as part of that transaction.
                                </li><br />


                            </ul>

                            <br />

                            <h3>Security of Your Informationn</h3>

                            <p>We take reasonable steps to protect your personal data from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
                            </p>

                            <br />

                            <h3>Your Rights and Choices</h3>

                            <p>You have the right to:
                            </p>

                            <ul>
                                <li>⦁ Access and update your personal information
                                </li> <br />
                                <li>⦁ Request the deletion of your data under certain circumstances.

                                </li><br />
                                <li>⦁ Opt out of marketing communications by following the unsubscribe instructions included in the emails.
                                </li><br />

                                <p>To exercise these rights, please contact us at info@ndtechland.com.</p>


                            </ul>

                            <br />

                            <h3>Cookies and Tracking Technologies</h3>

                            <p>We use cookies and similar technologies to improve user experience and analyze website performance. You can control the use of cookies through your browser settings, though disabling cookies may affect your ability to use certain features of our Services.

                            </p>

                            <br />

                            <h3>Data Retention
                            </h3>

                            <p>We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law

                            </p>

                            <br />

                            <h3>Changes to This Privacy Policy
                            </h3>

                            <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the new Privacy Policy on our website with the updated effective date.


                            </p>

                            <br />

                            <h3>Contact Us
                            </h3>

                            <ul>
                                <li>If you have any questions or concerns about this Privacy Policy, please contact us at:

                                </li> <br />
                                <li>N D Techland Private Limited

                                </li><br />
                                <li>C-53, Sector 2, Noida-201301
                                </li><br />
                                <li>Email: info@ndtechland.com
                                </li><br />

                                <p>Customer Support: 0120 4207742</p>


                            </ul>



                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Privacypolicy
