import React from 'react'
import { Link } from 'react-router-dom'
const Termsconditions = () => {
    return (
        <div>
            <section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
                <div className="text-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 v-center">
                                <div className="bread-inner">
                                    <div className="bread-menu">
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><a href="#">Footer</a></li>
                                        </ul>
                                    </div>
                                    <div className="bread-title">
                                        <h2>Terms and Conditions</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-agency pad-tb block-1">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="common-heading text-l ">
                                <span>Terms and Conditions</span>

                                <p style={{ textAlign: "justify" }}> These Terms and Conditions ("Terms") govern your use of the services provided by N D Techland Private Limited ("Company," "we," "us," or "our"). By accessing or using our software, web apps, mobile apps, website design services, and internet marketing solutions (collectively, the "Services"), you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our Services.
                                </p>
                            </div>

                            <h3>Acceptance of Terms</h3>
                            <p>By using our Services, you affirm that you are at least 18 years old or have the legal capacity to enter into these Terms. Your use of the Services is also governed by our Privacy Policy, which is incorporated by reference into these Terms.</p>

                            <br />

                            <h3>Services</h3>

                            <p>N D Techland Private Limited provides various services, including but not limited to software development, web and mobile application development, website designing, and internet marketing solutions. These Services are subject to change, and we reserve the right to modify or discontinue any Service at our discretion.</p>


                            <br />

                            <h3>User Responsibilities</h3>

                            <p>When using our Services, you agree to:</p>

                            <ul>
                                <li>⦁ Provide accurate and current information when required.
                                </li> <br />
                                <li>⦁ Maintain the confidentiality of your account credentials and be responsible for all activities under your account
                                </li><br />
                                <li>⦁ Use the Services only for lawful purposes and in accordance with these Terms
                                </li><br />
                                <li>⦁ Refrain from engaging in any activity that could harm or disrupt the functionality of the Services.
                                </li><br />


                            </ul>

                            <br />

                            <h3>Payment Terms</h3>

                            <p>For paid services, you agree to pay all fees and charges associated with your account in a timely manner. All payments are subject to the terms and conditions of the applicable payment processors. We reserve the right to suspend or terminate your access to the Services for any unpaid or overdue amounts.

                            </p>

                            <br />

                            <h3>Intellectual Property Rights</h3>

                            <p>All content, software, and materials provided as part of our Services, including but not limited to text, graphics, logos, images, and code, are the intellectual property of N D Techland Private Limited or its licensors. You may not use, reproduce, distribute, or create derivative works from our content without express written permission from us.

                            </p>

                            <br />

                            <h3>License to Use</h3>

                            <p>We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our Services solely for your personal or business purposes, in accordance with these Terms. This license does not include any resale or commercial use of the Services or its contents

                            </p>

                            <br />

                            <h3>Third-Party Services
                            </h3>

                            <p>Our Services may integrate with or include links to third-party services or content. N D Techland Private Limited does not control or endorse any third-party services, and your use of such services is subject to the terms and conditions of those third parties. We are not responsible for the content or practices of third-party services.


                            </p>

                            <br />

                            <h3>Disclaimer of Warranties
                            </h3>

                            <p>Our Services are provided on an "as is" and "as available" basis. N D Techland Private Limited makes no warranties, whether express or implied, regarding the reliability, accuracy, or availability of the Services. We disclaim all warranties of any kind, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.


                            </p>

                            <br />

                            <h3>Limitation of Liability
                            </h3>

                            <p>
                                To the fullest extent permitted by law, N D Techland Private Limited shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, or business opportunities, arising out of or in connection with your use of the Services, even if we have been advised of the possibility of such damages. <br />
                                In no event shall our total liability to you for any claims arising from or related to these Terms or the use of the Services exceed the amount you paid to us in the 12 months preceding the claim.

                            </p>

                            <br />

                            <h3>Indemnification
                            </h3>

                            <p>You agree to indemnify, defend, and hold harmless N D Techland Private Limited, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Services or breach of these Terms.
                            </p>
                            <br />

                            <h3>Termination
                            </h3>

                            <p>We may terminate or suspend your access to the Services at any time, with or without cause or notice, including for any violation of these Terms. Upon termination, all rights and licenses granted to you under these Terms will immediately cease.

                            </p>
                            <br />
                            <h3>Governing Law
                            </h3>

                            <p>These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. You agree to submit to the exclusive jurisdiction of the courts located in Noida, India, for any disputes arising out of or relating to these Terms or the Services

                            </p>

                            <br />
                            <h3>Changes to the Terms
                            </h3>

                            <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our website. Your continued use of the Services after any such changes constitutes your acceptance of the revised Terms.

                            </p>
                            <br />
                            <h3>Contact Us
                            </h3>

                            <p>We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting the updated Terms on our website. Your continued use of the Services after any such changes constitutes your acceptance of the revised Terms.

                            </p>

                            <ul>
                                <li>If you have any questions or concerns about these Terms, please contact us at:

                                </li> <br />
                                <li>N D Techland Private Limited

                                </li><br />
                                <li>C-53, Sector 2, Noida-201301
                                </li><br />
                                <li>Email: info@ndtechland.com
                                </li><br />

                                <p>Customer Support: 0120 4207742</p>


                            </ul>


                        </div>
                    </div>
                </div>
            </section>



        </div>
    )
}

export default Termsconditions
